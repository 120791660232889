import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Homepage from './components/Homepage';
import Login from './components/Login';
import ModernDashboardLayout from './components/ModernDashboardLayout';
import { selectUser, selectLoginTimestamp, logout } from './store/slices/authSlice';
import PublicSurvey from './components/Survey/PublicSurvey';
import PublicSalaryUpgradeForm from './components/SalaryUpgrade/PublicSalaryUpgradeForm';
import CashierGuide from './components/CashierGuide/CashierGuide';
import PayrollCalculator from './components/PayrollCalculator/PayrollCalculator';
import CalculatorSwitcher from './components/PayrollCalculator/CalculatorSwitcher';

const LOGIN_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

const PrivateRoute = ({ children }) => {
  const user = useSelector(selectUser);
  return user ? children : <Navigate to="/login" replace />;
};

function App() {
  const user = useSelector(selectUser);
  const loginTimestamp = useSelector(selectLoginTimestamp);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkLoginStatus = () => {
      if (user && loginTimestamp) {
        const currentTime = Date.now();
        if (currentTime - loginTimestamp > LOGIN_EXPIRATION_TIME) {
          dispatch(logout());
        }
      }
    };

    const intervalId = setInterval(checkLoginStatus, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [user, loginTimestamp, dispatch]);

  const handleLogout = () => {
    console.log('Logging out');
    dispatch(logout());
    localStorage.removeItem('user');
    localStorage.removeItem('loginTimestamp');
  };

  return (
    <Router>
      <Toaster position="top-center" reverseOrder={false} />
      
      <Routes>
        <Route 
          path="/" 
          element={user ? <Navigate to="/dashboard" replace /> : <Homepage />} 
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/dashboard" replace /> : <Login />}
        />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <ModernDashboardLayout user={user} onLogout={handleLogout} />
            </PrivateRoute>
          }
        />
        <Route path="/survey/:surveyId" element={<PublicSurvey />} />
        <Route path="/salary-survey" element={<PublicSalaryUpgradeForm />} />
        <Route path="/cashier-guide" element={<CashierGuide />} />
        <Route path="/payroll-calculator" element={<CalculatorSwitcher />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;