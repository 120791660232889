import React, { useState } from 'react';
import SimplePayrollCalculator from './SimplePayrollCalculator';
import PayrollCalculator from './PayrollCalculator';
import { Card } from '../ui/card';

const CalculatorSwitcher = () => {
  const [activeCalculator, setActiveCalculator] = useState('simple');

  return (
    <div className="w-full max-w-2xl mx-auto space-y-4" dir="rtl">
      <Card className="p-4 bg-white shadow-sm">
        <div className="flex justify-center gap-3">
          <button
            onClick={() => setActiveCalculator('simple')}
            className={`
              flex-1 px-6 py-3 rounded-lg text-lg font-semibold transition-all
              ${activeCalculator === 'simple'
                ? 'bg-purple-600 text-white shadow-md hover:bg-purple-700'
                : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
              }
            `}
          >
            <div className="flex flex-col items-center gap-1">
              <span>מחשבון מהיר</span>
              <span className="text-xs font-normal">
                {activeCalculator === 'simple' ? 'פעיל כעת' : 'חישוב שעות בסיסי'}
              </span>
            </div>
          </button>

          <button
            onClick={() => setActiveCalculator('detailed')}
            className={`
              flex-1 px-6 py-3 rounded-lg text-lg font-semibold transition-all
              ${activeCalculator === 'detailed'
                ? 'bg-purple-600 text-white shadow-md hover:bg-purple-700'
                : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
              }
            `}
          >
            <div className="flex flex-col items-center gap-1">
              <span>מחשבון מפורט</span>
              <span className="text-xs font-normal">
                {activeCalculator === 'detailed' ? 'פעיל כעת' : 'חישוב מלא לפי מחלקות'}
              </span>
            </div>
          </button>
        </div>

        <div className="mt-3 text-center">
          <p className="text-sm text-gray-500">
            {activeCalculator === 'simple' 
              ? 'מחשבון מהיר לחישוב שעות עבודה זמינות בסניף'
              : 'מחשבון מפורט הכולל חלוקה למחלקות וחישובי יעילות'
            }
          </p>
        </div>
      </Card>

      {activeCalculator === 'simple' ? <SimplePayrollCalculator /> : <PayrollCalculator />}
    </div>
  );
};

export default CalculatorSwitcher; 