import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { AlertTriangle } from 'lucide-react';

const PayrollCalculator = () => {
  // State variables with localStorage
  const [revenue, setRevenue] = useState(() => localStorage.getItem('complex_revenue') || '362000');
  const [productivityTarget, setProductivityTarget] = useState(() => localStorage.getItem('complex_productivityTarget') || '13.5');
  
  // מחלקת קצביה
  const [butcheryPercent, setButcheryPercent] = useState(() => localStorage.getItem('complex_butcheryPercent') || '20');
  const [butcheryHourlyRate, setButcheryHourlyRate] = useState(() => localStorage.getItem('complex_butcheryHourlyRate') || '50');
  
  // מחלקת ירקות
  const [producePercent, setProducePercent] = useState(() => localStorage.getItem('complex_producePercent') || '15');
  const [produceHourlyRate, setProduceHourlyRate] = useState(() => localStorage.getItem('complex_produceHourlyRate') || '50');
  
  // שכר ממוצע כללי
  const [avgHourlyRate, setAvgHourlyRate] = useState(() => localStorage.getItem('complex_avgHourlyRate') || '50');
  
  // הגדרות זמני עבודה
  const [regularHours, setRegularHours] = useState(() => localStorage.getItem('complex_regularHours') || '8');
  const [fridayHours, setFridayHours] = useState(() => localStorage.getItem('complex_fridayHours') || '5');

  // שמירה ב-localStorage בכל שינוי
  useEffect(() => {
    localStorage.setItem('complex_revenue', revenue);
    localStorage.setItem('complex_productivityTarget', productivityTarget);
    localStorage.setItem('complex_butcheryPercent', butcheryPercent);
    localStorage.setItem('complex_butcheryHourlyRate', butcheryHourlyRate);
    localStorage.setItem('complex_producePercent', producePercent);
    localStorage.setItem('complex_produceHourlyRate', produceHourlyRate);
    localStorage.setItem('complex_avgHourlyRate', avgHourlyRate);
    localStorage.setItem('complex_regularHours', regularHours);
    localStorage.setItem('complex_fridayHours', fridayHours);
  }, [revenue, productivityTarget, butcheryPercent, butcheryHourlyRate, 
      producePercent, produceHourlyRate, avgHourlyRate, regularHours, fridayHours]);

  // חישוב שעות עבודה שבועיות
  const weeklyHours = (Number(regularHours) * 5) + Number(fridayHours);

  // פישוב אחוז נותר למחלקה כללית
  const remainingPercent = 100 - Number(butcheryPercent) - Number(producePercent);

  // פונקציית חישוב שעות עבודה
  const calculateWorkers = (revenue, percentage, productivityTarget, hourlyRate) => {
    const departmentRevenue = (Number(revenue) * Number(percentage)) / 100;
    const availablePayroll = (departmentRevenue * Number(productivityTarget)) / 100;
    return availablePayroll / Number(hourlyRate);
  };

  // חישוב שעות למחלקות ספציפיות (בלי עיגול)
  const butcheryHours = calculateWorkers(revenue, butcheryPercent, productivityTarget, butcheryHourlyRate);
  const produceHours = calculateWorkers(revenue, producePercent, productivityTarget, produceHourlyRate);
  const generalHours = calculateWorkers(revenue, remainingPercent, productivityTarget, avgHourlyRate);

  // סיכום כל השעות ועיגול רק בסוף
  const totalAvailableHours = Math.round(butcheryHours + produceHours + generalHours);

  // חישוב מספר העובדים לפי סך השעות
  const butcheryWorkers = Math.round(butcheryHours / weeklyHours);
  const produceWorkers = Math.round(produceHours / weeklyHours);
  const generalWorkers = Math.round(generalHours / weeklyHours);

  // חישוב שעות זמינות לפי מספר העובדים
  const butcheryAvailableHours = Math.round(butcheryHours);
  const produceAvailableHours = Math.round(produceHours);
  const generalAvailableHours = Math.round(generalHours);

  // חישוב סה"כ עובדים בסניף
  const totalStaffCount = generalWorkers + butcheryWorkers + produceWorkers;

  return (
    <Card className="w-full max-w-2xl mx-auto relative" dir="rtl">
      <CardHeader className="bg-white">
        <div className="flex justify-between items-center">
          <CardTitle className="text-xl font-bold text-gray-800">מחשבון עובדים לפי פדיון סניף</CardTitle>
          <span className="text-[10px] sm:text-xs text-gray-400">פותח ע״י ישי אלמולי</span>
        </div>
      </CardHeader>
      
      <CardContent className="space-y-6">
        {/* הסבר פשוט */}
        <div className="text-right text-sm space-y-2 bg-blue-50 p-4 rounded-lg">
          <p className="font-bold text-lg text-blue-800">איך זה עובד?</p>
          <p>1. הכנס את הפדיון השבועי הצפוי של הסניף</p>
          <p>2. וודא שיעד הפריון שלך מעודכן (נקבע על ידי ההנהלה)</p>
          <p>3. הגדר את החלק היחסי של כל מחלקה מהפדיון</p>
          <p>המחשבון יראה לך כמה עובדים דרושים בכל מחלקה!</p>
        </div>

        {/* שדות קלט מרכזיים */}
        <div className="space-y-6 bg-white p-6 rounded-lg border">
          <div className="flex flex-col gap-2">
            <label className="text-lg font-bold text-gray-700">פדיון שבועי צפוי (₪)</label>
            <input
              type="number"
              value={revenue}
              onChange={(e) => setRevenue(e.target.value)}
              className="p-4 border rounded-lg text-right text-xl w-full"
              dir="rtl"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-lg font-bold text-gray-700">יעד פריון (%)</label>
            <input
              type="number"
              value={productivityTarget}
              onChange={(e) => setProductivityTarget(e.target.value)}
              className="p-4 border rounded-lg text-right text-xl w-full"
              dir="rtl"
            />
            <p className="text-sm text-gray-500">היעד נקבע על ידי ההנהלה באופן פרטני לכל סניף</p>
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-lg font-bold text-gray-700">עלות שעת עבודה ממוצעת (₪)</label>
            <input
              type="number"
              value={avgHourlyRate}
              onChange={(e) => setAvgHourlyRate(e.target.value)}
              className="p-4 border rounded-lg text-right text-xl w-full"
              dir="rtl"
            />
          </div>
        </div>

        {/* הגדרות מחלקות */}
        <div className="grid md:grid-cols-2 gap-4">
          {/* מחלקת קצביה */}
          <div className="bg-red-50 p-4 rounded-lg border border-red-100 space-y-4">
            <h3 className="font-bold text-red-800 text-lg">מחלקת קצביה</h3>
            <div className="space-y-4">
              <div>
                <label className="font-semibold text-gray-700">אחוז מהפדיון (%)</label>
                <input
                  type="number"
                  value={butcheryPercent}
                  onChange={(e) => setButcheryPercent(e.target.value)}
                  className="mt-1 p-3 border rounded-lg text-right w-full"
                  dir="rtl"
                />
              </div>
              <div>
                <label className="font-semibold text-gray-700">עלות שעת עבודה ממוצעת לקצב (₪)</label>
                <input
                  type="number"
                  value={butcheryHourlyRate}
                  onChange={(e) => setButcheryHourlyRate(e.target.value)}
                  className="mt-1 p-3 border rounded-lg text-right w-full"
                  dir="rtl"
                />
              </div>
            </div>
          </div>

          {/* מחלקת ירקות */}
          <div className="bg-green-50 p-4 rounded-lg border border-green-100 space-y-4">
            <h3 className="font-bold text-green-800 text-lg">מחלקת ירקות</h3>
            <div className="space-y-4">
              <div>
                <label className="font-semibold text-gray-700">אחוז מהפדיון (%)</label>
                <input
                  type="number"
                  value={producePercent}
                  onChange={(e) => setProducePercent(e.target.value)}
                  className="mt-1 p-3 border rounded-lg text-right w-full"
                  dir="rtl"
                />
              </div>
              <div>
                <label className="font-semibold text-gray-700">עלות שעת עבודה ממוצעת לירקן (₪)</label>
                <input
                  type="number"
                  value={produceHourlyRate}
                  onChange={(e) => setProduceHourlyRate(e.target.value)}
                  className="mt-1 p-3 border rounded-lg text-right w-full"
                  dir="rtl"
                />
              </div>
            </div>
          </div>
        </div>

        {/* הגדרות זמני עבודה */}
        <div className="bg-gray-50 p-4 rounded-lg border space-y-4">
          <h3 className="font-bold text-gray-800">הגדרות זמני עבודה</h3>
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label className="font-semibold text-gray-700">שעות עבודה ביום רגיל</label>
              <input
                type="number"
                min="1"
                max="12"
                value={regularHours}
                onChange={(e) => setRegularHours(e.target.value)}
                className="mt-1 p-3 border rounded-lg text-right w-full"
                dir="rtl"
              />
            </div>
            <div>
              <label className="font-semibold text-gray-700">שעות עבודה ביום שישי</label>
              <input
                type="number"
                min="1"
                max="12"
                value={fridayHours}
                onChange={(e) => setFridayHours(e.target.value)}
                className="mt-1 p-3 border rounded-lg text-right w-full"
                dir="rtl"
              />
            </div>
          </div>
          <div className="bg-blue-50 p-3 rounded-lg">
            <p className="text-sm text-gray-600">
              סה"כ שעות עבודה שבועיות: {weeklyHours} שעות
              <br />
              (5 ימים × {regularHours} שעות + יום שישי {fridayHours} שעות)
            </p>
          </div>
        </div>

        {/* הסבר על החישוב */}
        <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-200">
          <div className="flex items-start gap-3">
            <AlertTriangle className="text-yellow-600 mt-1" size={20} />
            <div className="space-y-2">
              <p className="font-bold text-gray-800">שיטת החישוב</p>
              <p className="text-sm text-gray-600">
                מספר העובדים מחושב באופן הבא:
                <br />
                1. קצבים וירקנים מחושבים לפי האחוז שלהם מהפדיון
                <br />
                2. שאר העובדים (כלליים) מחושבים על הפדיון הנותר ({remainingPercent}%)
                <br />
                3. סה"כ עובדים בסניף הוא סכום של כל העובדים יחד
              </p>
            </div>
          </div>
        </div>

{/* תוצאות */}
<div className="bg-gray-50 p-6 rounded-lg border space-y-6">
  <div className="bg-purple-50 p-4 rounded-lg border border-purple-100 text-center mb-4">
    <p className="text-gray-600">סה"כ עובדים נדרשים בסניף</p>
    <p className="text-4xl font-bold text-purple-600">{totalStaffCount}</p>
    <p className="text-sm text-gray-500">סה"כ משרות מלאות (כולל כל המחלקות)</p>
    <div className="mt-2 text-gray-600">
      <p>סה"כ שעות לשיבוץ</p>
      <p className="text-2xl font-bold text-purple-600">{totalAvailableHours}</p>
    </div>
  </div>

          <div className="grid gap-4">
            <div className="bg-white p-4 rounded-lg border text-center">
              <p className="text-gray-600">עובדי הסניף (ללא קצבים וירקנים)</p>
              <p className="text-3xl font-bold text-blue-600">{generalWorkers}</p>
              <p className="text-sm text-gray-500">משרות מלאות</p>
              <p className="text-gray-600">סה"כ שעות פנויות</p>
              <p className="text-3xl font-bold text-blue-600">{generalAvailableHours}</p>
            </div>

            <div className="grid md:grid-cols-2 gap-4">
              <div className="bg-red-50 p-4 rounded-lg border border-red-100 text-center">
                <p className="text-gray-600">קצבים</p>
                <p className="text-3xl font-bold text-red-600">{butcheryWorkers}</p>
                <p className="text-sm text-gray-500">משרות מלאות</p>
                <p className="text-gray-600">סה"כ שעות לשיבוץ</p>
                <p className="text-3xl font-bold text-red-600">{butcheryAvailableHours}</p>
              </div>

              <div className="bg-green-50 p-4 rounded-lg border border-green-100 text-center">
                <p className="text-gray-600">ירקנים</p>
                <p className="text-3xl font-bold text-green-600">{produceWorkers}</p>
                <p className="text-sm text-gray-500">משרות מלאות</p>
                <p className="text-gray-600">סה"כ שעות לשיבוץ</p>
                <p className="text-3xl font-bold text-green-600">{produceAvailableHours}</p>
              </div>
            </div>
          </div>
        </div>

        {/* הערות */}
        <div className="text-right text-sm text-gray-500 space-y-2">
          <p>• החישובים מבוססים על שעות עבודה שבועיות כפי שהוגדרו</p>
          <p>• המספרים מעוגלים לספרה אחת אחרי הנקודה</p>
          <p>• אחוז המחלקות המיוחדות: קצביה {butcheryPercent}%, ירקות {producePercent}%, כללי {remainingPercent}%</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default PayrollCalculator;