import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';

const SimplePayrollCalculator = () => {
  // State variables with localStorage
  const [revenue, setRevenue] = useState(() => localStorage.getItem('simple_revenue') || '362000');
  const [productivityTarget, setProductivityTarget] = useState(() => localStorage.getItem('simple_productivityTarget') || '13.5');
  const [avgHourlyRate, setAvgHourlyRate] = useState(() => localStorage.getItem('simple_avgHourlyRate') || '50');
  const [regularHours, setRegularHours] = useState(() => localStorage.getItem('simple_regularHours') || '8');
  const [fridayHours, setFridayHours] = useState(() => localStorage.getItem('simple_fridayHours') || '5');

  // שמירה ב-localStorage בכל שינוי
  useEffect(() => {
    localStorage.setItem('simple_revenue', revenue);
    localStorage.setItem('simple_productivityTarget', productivityTarget);
    localStorage.setItem('simple_avgHourlyRate', avgHourlyRate);
    localStorage.setItem('simple_regularHours', regularHours);
    localStorage.setItem('simple_fridayHours', fridayHours);
  }, [revenue, productivityTarget, avgHourlyRate, regularHours, fridayHours]);

  // Calculate weekly payroll
  const weeklyPayroll = (Number(revenue) * Number(productivityTarget)) / 100;

  // Calculate total available hours
  const totalAvailableHours = Math.round(weeklyPayroll / Number(avgHourlyRate));

  // Calculate weekly hours
  const weeklyHours = (Number(regularHours) * 5) + Number(fridayHours);

  // Calculate required workers
  const requiredWorkers = Math.round(totalAvailableHours / weeklyHours);

  return (
    <Card className="w-full max-w-2xl mx-auto relative" dir="rtl">
      <CardHeader className="bg-white">
        <div className="flex justify-between items-center">
          <CardTitle className="text-xl font-bold text-gray-800">מחשבון פשוט לשעות פנויות</CardTitle>
          <span className="text-[10px] sm:text-xs text-gray-400">פותח ע״י ישי אלמולי</span>
        </div>
      </CardHeader>
      
      <CardContent className="space-y-6">
        {/* הסבר פשוט */}
        <div className="text-right text-sm space-y-2 bg-blue-50 p-4 rounded-lg">
          <p className="font-bold text-lg text-blue-800">איך זה עובד?</p>
          <p>1. הכנס את הפדיון השבועי הצפוי של הסניף</p>
          <p>2. הגדר את יעד הפריון שנקבע לסניף</p>
          <p>3. וודא שעלות שעת העבודה הממוצעת מעודכנת</p>
          <p>המחשבון יציג את סך השעות הפנויות השבועיות!</p>
        </div>

        {/* Input fields */}
        <div className="space-y-6 bg-white p-6 rounded-lg border">
          <div className="flex flex-col gap-2">
            <label className="text-lg font-bold text-gray-700">פדיון שבועי צפוי (₪)</label>
            <input
              type="number"
              value={revenue}
              onChange={(e) => setRevenue(e.target.value)}
              className="p-4 border rounded-lg text-right text-xl w-full"
              dir="rtl"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-lg font-bold text-gray-700">יעד פריון (%)</label>
            <input
              type="number"
              value={productivityTarget}
              onChange={(e) => setProductivityTarget(e.target.value)}
              className="p-4 border rounded-lg text-right text-xl w-full"
              dir="rtl"
            />
            <p className="text-sm text-gray-500">היעד נקבע על ידי ההנהלה באופן פרטני לכל סניף</p>
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-lg font-bold text-gray-700">עלות שעת עבודה ממוצעת (₪)</label>
            <input
              type="number"
              value={avgHourlyRate}
              onChange={(e) => setAvgHourlyRate(e.target.value)}
              className="p-4 border rounded-lg text-right text-xl w-full"
              dir="rtl"
            />
          </div>
        </div>

        {/* הוספת הגדרות זמני עבודה */}
        <div className="bg-gray-50 p-4 rounded-lg border space-y-4">
          <h3 className="font-bold text-gray-800">הגדרות זמני עבודה</h3>
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label className="font-semibold text-gray-700">שעות עבודה ביום רגיל</label>
              <input
                type="number"
                min="1"
                max="12"
                value={regularHours}
                onChange={(e) => setRegularHours(e.target.value)}
                className="mt-1 p-3 border rounded-lg text-right w-full"
                dir="rtl"
              />
            </div>
            <div>
              <label className="font-semibold text-gray-700">שעות עבודה ביום שישי</label>
              <input
                type="number"
                min="1"
                max="12"
                value={fridayHours}
                onChange={(e) => setFridayHours(e.target.value)}
                className="mt-1 p-3 border rounded-lg text-right w-full"
                dir="rtl"
              />
            </div>
          </div>
          <div className="bg-blue-50 p-3 rounded-lg">
            <p className="text-sm text-gray-600">
              סה"כ שעות עבודה שבועיות לעובד: {weeklyHours} שעות
              <br />
              (5 ימים × {regularHours} שעות + יום שישי {fridayHours} שעות)
            </p>
          </div>
        </div>

        {/* עדכון הצגת התוצאות */}
        <div className="bg-gray-50 p-6 rounded-lg border text-center">
          <p className="text-gray-600">סה"כ שעות פנויות לשיבוץ</p>
          <p className="text-4xl font-bold text-purple-600">{totalAvailableHours}</p>
          <p className="mt-4 text-gray-600">מספר עובדים נדרש</p>
          <p className="text-4xl font-bold text-blue-600">{requiredWorkers}</p>
          <p className="text-sm text-gray-500">
            (לפי {weeklyHours} שעות שבועיות לעובד)
          </p>
        </div>

        {/* הערות */}
        <div className="text-right text-sm text-gray-500 space-y-2">
          <p>• החישוב מבוסס על הנוסחה: (פדיון × יעד פריון) ÷ עלות שעת עבודה</p>
          <p>• התוצאה מעוגלת למספר שלם של שעות</p>
          <p>• יש להתייעץ עם ההנהלה לגבי היעדים המדויקים לסניף</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default SimplePayrollCalculator; 